import React from 'react';
// import { Map } from 'google-maps-react';
import { useSelector } from 'react-redux';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import FaceIcon from '@material-ui/icons/Face';

import Class from '../../../store/entities/class/type';
import Notification from '../../../store/entities/notification/type';
import State from '../../../store/entities/state/type';
import { invoiceSelectors } from '../../../store/entities/invoice';
import { stateSelectors } from '../../../store/entities/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        paper: {
            padding: theme.spacing(1),
            margin: 'auto',
        },
        map: {
            width: 250,
            height: 250,
        },
        img: {
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
    }),
);

function notificationDetails(
    notifications: Notification[],
    stateList: State[],
): {
    state_abbr: string;
    type: string;
    status: string;
    date: string;
}[] {
    const stateIds = new Set(notifications.map((n) => n.state_id));

    const notifList = Array.from(stateIds).map((stateId) => {
        const state = stateList.find((s) => s.id === stateId) || ({} as State);
        const response = {
            state_abbr: state.abbreviation,
            type: 'No Notifications Found',
            status: '',
            date: '',
        };
        let latestNotif: Notification[] = [];
        const stateNotifs = notifications.filter((n) => n.state_id === stateId);

        // array of notification types sorted in descending order
        // META relevant notification types by id:
        //     6 === initial
        //     7 === change
        //     8 === cancel
        const notifTypes = stateNotifs
            .filter(
                (n) =>
                    n.notification_types_id > 5 && n.notification_types_id < 9,
            )
            .map((n) => n.notification_types_id)
            .sort()
            .reverse();

        // If notifications found don't match META types, return default
        if (!notifTypes.length) {
            return response;
        }

        switch (notifTypes[0]) {
            case 6:
                latestNotif = stateNotifs.filter(
                    (n) => n.notification_types_id === 6,
                );
                response.type = 'initial';
                break;
            case 7:
                latestNotif = stateNotifs.filter(
                    (n) => n.notification_types_id === 7,
                );
                response.type = 'change';
                break;
            case 8:
                latestNotif = stateNotifs.filter(
                    (n) => n.notification_types_id === 8,
                );
                response.type = 'cancel';
                break;
            default:
        }

        switch (latestNotif[0].status) {
            case 'CREATED':
                response.status = 'scheduled on';
                response.date = new Date(
                    latestNotif[0].scheduled_at,
                ).toLocaleDateString();
                break;
            case 'CANCELLED':
                response.status = 'cancelled';
                response.date = new Date(
                    latestNotif[0].updated_at,
                ).toLocaleDateString();
                break;
            default:
                response.status = 'sent on';
                response.date = new Date(
                    latestNotif[0].scheduled_at,
                ).toLocaleDateString();
        }

        return response;
    });

    return notifList;
}

export interface Props extends React.HTMLProps<HTMLDivElement> {
    classInstance: Class;
    trainerNames: string[];
}

export default function ClassDetails(props: Props) {
    const styleClasses = useStyles();
    const { className, classInstance, trainerNames, ...attrs } = props;
    const address = classInstance.sessions[0]?.location;
    const stateList = useSelector(stateSelectors.selectAll);
    const invoices = useSelector(invoiceSelectors.selectAll).filter(
        ({ notes }) => !notes?.includes('VOIDED'),
    );
    const notificationList = notificationDetails(
        classInstance.notifications || [],
        stateList,
    );
    const thisClassInvoices = invoices.filter(
        (inv) => inv.seats[0]?.class_id === classInstance.id,
    );
    const invoiceTotal = thisClassInvoices
        .map((inv) => inv.amount)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const invoicePaid = thisClassInvoices
        .map((inv) => inv.paid_amount)
        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
    const invoiceDue = invoiceTotal - invoicePaid;

    return (
        <div {...attrs} className={styleClasses.root}>
            <Paper className={styleClasses.paper}>
                <Grid container spacing={2} justify="flex-start">
                    <Grid item>
                        <ButtonBase className={styleClasses.map}>
                            {/* <Map google={window.google} /> */}
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={9} container direction="row">
                        <Grid item xs>
                            <Typography>{address?.addressee}</Typography>
                            <Typography variant="body2">
                                {address?.address_1}
                            </Typography>
                            <Typography variant="body2">
                                {`${address?.city}, ${address?.state}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {address?.zip_code}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography>Billing Status</Typography>
                            <Typography variant="body2">
                                {`Total Due: ${invoiceTotal}`}
                            </Typography>
                            <Typography variant="body2">
                                {`Balance Remaining: ${invoiceDue}`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs
                            container
                            spacing={1}
                            direction="column"
                            justify="flex-start"
                        >
                            {trainerNames.map((name) => (
                                <Grid item key={name}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FaceIcon
                                            className={styleClasses.small}
                                        />
                                        <div>{name}</div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            container
                            spacing={1}
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                        >
                            {notificationList.map((notif) => (
                                <Grid
                                    item
                                    key={notif.state_abbr}
                                    container
                                    spacing={5}
                                    direction="row"
                                    justify="space-evenly"
                                >
                                    <Grid item>
                                        <Typography variant="body2">
                                            {notif.state_abbr}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {notif.type}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {notif.status}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2">
                                            {notif.date}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}
