import React from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';

import { Tooltip, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green, yellow, red } from '@material-ui/core/colors';
import {
    AccountCircle,
    SaveAlt,
    Face as FaceIcon,
    MonetizationOn as MonetizationOnIcon,
    LocationOn as LocationOnIcon,
    Edit as EditIcon,
    Info as InfoIcon,
} from '@material-ui/icons';

import DateFilterDisplay, {
    dateFilterList,
    dateFilterLogic,
    defaultDateFilter,
} from './DateFilterDisplay';
import TrainerFilterDisplay, {
    trainerFilterList,
    trainerFilterLogic,
} from './TrainerFilterDisplay';
import CanceledFilterDisplay, {
    CanceledFilterList,
    CanceledFilterLogic,
} from './CanceledFilterDisplay';
import AddStudentDialog from '../../AddStudentDialog';
import { NotificationStateChips } from '../../StateChipLists';
import verifyAdmin from '../../../utils/verifyAdmin';

import Class from '../../../store/entities/class/type';
import Invoice from '../../../store/entities/invoice/type';
import Session from '../../../store/entities/session/type';
import Trainer from '../../../store/entities/trainer/type';
import User from '../../../store/entities/user/type';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '80px',
            bottom: '70px',
            right: '20px',
            left: '20px',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        containerMR: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        green: {
            color: green[600],
        },
        yellow: {
            color: yellow[700],
        },
        red: {
            color: red[600],
        },
    }),
);

function findPrincipalTrainer(
    sessions: Session[],
    principalId: number,
): Trainer {
    const principalTrainer: Trainer[] = [];
    sessions.forEach((s) => {
        s.trainers.forEach((t) => {
            if (t.id === principalId) {
                principalTrainer.push(t);
            }
        });
    });
    return principalTrainer[0];
}

function flCourseNum(abbreviation: string): string {
    switch (abbreviation) {
        case 'ASBWI':
        case 'ASBSWI':
        case 'WI':
        case 'SWI':
            return '0002841';
        case 'ASBWR':
        case 'ASBSWR':
        case 'WR':
        case 'SWR':
            return '0004720';
        case 'ASBSI':
        case 'SI':
            return '0002838';
        case 'ASBSR':
        case 'SR':
            return '0004717';
        case 'ASBII':
        case 'II':
            return '0002839';
        case 'ASBIR':
        case 'IR':
            return '0004718';
        case 'ASBMPI':
        case 'MPI':
            return '0002840';
        case 'ASBMPR':
        case 'MPR':
            return '0004719';
        case 'ASBPDI':
        case 'PDI':
            return '0005750';
        case 'ASBPDR':
        case 'PDR':
            return '0005751';
        case 'ASBRP': // RESPIRATOR PROTECTION
        case 'RP':
            return '0006267';
        case 'NIOSH': // NIOSH 582
            return '0006266';
        case 'OASBIR': // Online Inspector Refresher
            return '0006410';
        case 'OASBMPR': // Online Management Planner Refresher
            return '0006409';
        default:
            return '0000000';
    }
}

function getCertData(
    classInstance: Class,
    invoices: Invoice[],
    students: User[],
): string[] {
    const certData: string[] = [];
    const principalTrainer = findPrincipalTrainer(
        classInstance.sessions,
        classInstance.principal_trainer_id,
    );
    const loc = classInstance.sessions[0]?.location;
    const classEndDate = new Date(classInstance.sessions[0]?.date_end);
    const certExpirationDate = new Date(classEndDate).setFullYear(
        classEndDate.getFullYear() + 1,
    );
    const classData = {
        endDate: classEndDate.toLocaleDateString(),
        courseName: classInstance.course?.name,
        courseAbbr: classInstance.course?.abbreviations,
        location: `${loc?.city} ${loc?.state}`,
        trainerName: principalTrainer.user.full_name,
    };
    const userInfo: { student: User; state: string; certNumber: string }[] = [];
    invoices.forEach((inv) =>
        inv.seats.forEach((seat) =>
            seat.states.forEach((st) => {
                const abcs = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                const stateFirst = abcs.indexOf(st.abbreviation[0]) + 1;
                const stateLast = abcs.indexOf(st.abbreviation[1]) + 1;
                const userData = students.find((s) => s.id === seat.user_id);
                const [yr, mth, d] = classEndDate
                    .toISOString()
                    .split('T')[0]
                    .split('-');
                if (userData) {
                    const certNumFields: string[] = [
                        yr.slice(-2),
                        'ME',
                        `${userData.ssn_last_four_digits || 1234}`,
                        `${userData.first_name[0] || 'Z'}`,
                        `${userData.last_name[0] || 'Z'}`,
                        mth,
                        d,
                        `${Math.floor(stateFirst / 10)}${stateFirst % 10}`,
                        `${Math.floor(stateLast / 10)}${stateLast % 10}`,
                    ];
                    userInfo.push({
                        student: userData,
                        state: st.abbreviation,
                        certNumber: certNumFields.join('').toUpperCase(), // generateCertNumber(15),
                    });
                }
            }),
        ),
    );
    userInfo.forEach((info) => {
        const alLocation =
            info.state === 'AL'
                ? `${loc?.address_1} ${loc?.city} ${loc?.state} ${loc?.zip_code}`
                : ' ';
        const certDataRow: string[] = [
            info.certNumber, // 'Cert Number'
            `${info.student.ssn_last_four_digits}`, // 'SSN'
            `${info.student.first_name}`, // 'First Name'
            `${info.student.last_name}`, // 'Last Name'
            classData.endDate, // 'End Date'
            classData.courseName, // 'Class Name (as shown on certificate)'
            classData.location, // 'Location'
            info.state, // 'State Certificate' = state for which cert is made
            classData.trainerName, // 'Trainer Name'
            new Date(certExpirationDate).toLocaleDateString(), // 'Start Date'
            new Date(certExpirationDate).toLocaleDateString(), // 'Expiration Date'
            alLocation, // 'Class Location (AL)' = address city state zip if for AL certs
            `${info.state === 'FL' ? info.student.fl_license_number : ' '}`, // 'FL License Number'
            `${info.student.DL_license}`, // 'Driver's License'
            `${info.student.address_id}`, // 'Student Address'
            `${info.state === 'FL' ? flCourseNum(classData.courseAbbr) : ' '}`, // 'FL Course Number' = obtained based on course abbrev.
        ];
        certData.push(certDataRow.join());
    });
    // Add class Id at end to identify data on backend
    certData.push(`${classInstance.id}`);
    return certData;
}

export function getCustomColumns(
    classes: Class[],
    invoices: Invoice[],
    students: User[],
    currentUser: User,
) {
    const styleClasses = useStyles();

    // https://github.com/gregnb/mui-datatables/issues/43#issuecomment-595214127
    const columnDefinitions: MUIDataTableColumnDef[] = [
        {
            name: 'date_filter',
            label: 'Date Filter',
            options: {
                display: 'false',
                filter: true,
                filterType: 'custom',
                filterList: defaultDateFilter, // defaults to current week
                customFilterListOptions: {
                    render: dateFilterList,
                },
                filterOptions: {
                    logic: dateFilterLogic,
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <DateFilterDisplay
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={column}
                        />
                    ),
                },
            },
        },
        {
            name: 'dateSort',
            label: 'Date Sorting',
            options: {
                display: 'false',
                sort: false,
                filter: false,
            },
        },
        {
            name: 'classStart',
            label: 'Class Start',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (index: number) => {
                    const startDate = new Date(
                        classes[index].sessions[0]?.date_start,
                    ).toLocaleDateString();
                    const thisClassInvoices = invoices.filter(
                        (inv) => inv.seats[0]?.class_id === classes[index].id,
                    );
                    const studentCnt = thisClassInvoices
                        .map((inv) => inv.seats.length)
                        .reduce((prev, curr) => prev + curr, 0);
                    const studentMax = classes[index].max_seats;
                    return (
                        <div style={{ width: '100px' }}>
                            <div>
                                <strong>{startDate}</strong>
                            </div>
                            <div className={styleClasses.container}>
                                <AccountCircle
                                    className={clsx(
                                        styleClasses.small,
                                        studentCnt < studentMax
                                            ? styleClasses.green
                                            : styleClasses.red,
                                    )}
                                />
                                <div>{`${studentCnt}/${studentMax}`}</div>
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: 'openClass',
            label: 'Enrollment Status',
            options: {
                display: 'true',
                sort: false,
                filter: true,
                customBodyRender: (openClass: string) => (
                    <div style={{ width: '100px' }}>
                        <strong>{openClass}</strong>
                    </div>
                ),
            },
        },
        {
            name: 'courseNumber',
            label: 'Class #',
            options: {
                sort: true,
                filter: false,
                customBodyRenderLite: (index: number) => {
                    const courseNumber = classes[index].id;
                    const thisClassInvoices = invoices.filter(
                        (inv) => inv.seats[0]?.class_id === classes[index].id,
                    );
                    const price = thisClassInvoices
                        .map((inv) => inv.amount)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    const totalPaid = thisClassInvoices
                        .map((inv) => inv.paid_amount)
                        .reduce((prev, curr) => Number(prev) + Number(curr), 0);
                    const monetizationIconColor = () => {
                        if (totalPaid >= price) {
                            return styleClasses.green;
                        }
                        if (totalPaid) {
                            return styleClasses.yellow;
                        }
                        return styleClasses.red;
                    };
                    return (
                        <div style={{ width: '100px' }}>
                            <div>
                                <strong>{`#${courseNumber}`}</strong>
                            </div>
                            <div className={styleClasses.container}>
                                <MonetizationOnIcon
                                    className={clsx(
                                        styleClasses.small,
                                        monetizationIconColor(),
                                    )}
                                />
                                <div>{price}</div>
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: 'courseName',
            label: 'Course Name',
            options: {
                sort: true,
                filter: true,
                customFilterListOptions: {
                    render: (v: string) => `Course Name: ${v}`,
                },
                customBodyRenderLite: (index: number) => {
                    const classTitle = classes[index].course?.name;
                    const loc = classes[index].sessions[0]?.location;
                    const locConfirmed =
                        classes[index].sessions[0]?.location_confirmed;
                    const city = loc?.city;
                    const state = loc?.state;
                    const principalTrainer = findPrincipalTrainer(
                        classes[index].sessions,
                        classes[index].principal_trainer_id,
                    );
                    return (
                        <div style={{ width: '300px' }}>
                            <strong>{classTitle}</strong> <br />
                            <span className={styleClasses.container}>
                                <div className={styleClasses.containerMR}>
                                    <LocationOnIcon
                                        className={clsx(
                                            styleClasses.small,
                                            locConfirmed
                                                ? styleClasses.green
                                                : styleClasses.red,
                                        )}
                                    />
                                    <div>{`${city}, ${state}`}</div>
                                </div>
                                <div className={styleClasses.container}>
                                    <FaceIcon className={styleClasses.small} />
                                    <div>
                                        {principalTrainer?.user.full_name}
                                    </div>
                                </div>
                            </span>
                        </div>
                    );
                },
            },
        },
        {
            name: 'certificationStates',
            label: 'States',
            options: {
                sort: false,
                filter: false,
                customBodyRenderLite: (index: number) => (
                    <NotificationStateChips
                        accreditations={
                            classes[index]?.class_accreditations || []
                        }
                        notifications={classes[index]?.notifications || []}
                    />
                ),
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRenderLite: (index: number) => (
                    <div
                        className={styleClasses.container}
                        style={{ width: '100px' }}
                    >
                        <AddStudentDialog
                            onConfirm={() => {}}
                            classId={classes[index].id}
                            invoices={invoices.filter(
                                (inv) =>
                                    inv.seats[0]?.class_id ===
                                    classes[index].id,
                            )}
                            disabled={
                                !verifyAdmin(currentUser) &&
                                new Date(
                                    classes[index].sessions[0]?.date_start,
                                ) <
                                    new Date(
                                        new Date().setDate(
                                            new Date().getDate() - 14,
                                        ),
                                    )
                            }
                        />
                        <Link
                            to={{
                                pathname: '/classform',
                                search: `?class_id=${classes[index].id}`,
                            }}
                        >
                            <Tooltip title="Change Notification">
                                <IconButton onClick={() => {}}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Link
                            to={{
                                pathname: '/classview',
                                search: `?class_id=${classes[index].id}`,
                            }}
                        >
                            <Tooltip title="View Class">
                                <IconButton onClick={() => {}}>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Download Certificate Data">
                            <IconButton
                                disabled={
                                    invoices.filter(
                                        (inv) =>
                                            inv.seats[0]?.class_id ===
                                            classes[index].id,
                                    ).length === 0
                                }
                                onClick={async () => {
                                    const certData = getCertData(
                                        classes[index],
                                        invoices.filter(
                                            (inv) =>
                                                inv.seats[0]?.class_id ===
                                                classes[index].id,
                                        ),
                                        students,
                                    );
                                    const response = await axios.post(
                                        `${window.location.origin}/certData`,
                                        certData,
                                    );
                                    window.location.href = response.data.url;
                                }}
                            >
                                <SaveAlt />
                            </IconButton>
                        </Tooltip>
                    </div>
                ),
            },
        },
        {
            name: 'location',
            label: 'Locations',
            options: {
                display: 'false',
                filter: true,
                customFilterListOptions: {
                    render: (v: string) => `Location: ${v}`,
                },
            },
        },
        {
            name: 'trainers',
            label: 'Trainers',
            options: {
                display: 'false',
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: trainerFilterList,
                },
                filterOptions: {
                    logic: trainerFilterLogic,
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <TrainerFilterDisplay
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={column}
                        />
                    ),
                },
            },
        },
        {
            name: 'canceled',
            label: 'Canceled',
            options: {
                display: 'false',
                filter: true,
                filterType: 'custom',
                filterList: [], // Default filter empty
                customFilterListOptions: {
                    render: CanceledFilterList,
                },
                filterOptions: {
                    logic: CanceledFilterLogic,
                    fullWidth: true,
                    display: (filterList, onChange, index, column) => (
                        <CanceledFilterDisplay
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={column}
                        />
                    ),
                },
            },
        },
    ];
    return columnDefinitions;
}
