import React from 'react';
import { useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FaceIcon from '@material-ui/icons/Face';

import Class from '../../../store/entities/class/type';
import Trainer from '../../../store/entities/trainer/type';
import { locationSelectors } from '../../../store/entities/location';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        marginVert: {
            margin: '20px 0px',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    classInstance: Class;
    principalTrainer?: Trainer;
}

export default function ClassViewSessions({
    classInstance,
    principalTrainer,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const sessionArray: JSX.Element[] = [];
    const location = useSelector((state) =>
        locationSelectors.selectById(
            state,
            classInstance.sessions?.[0]?.location_id || 0,
        ),
    );
    const locationAddress = [
        location?.address_1,
        location?.city,
        location?.state,
        location?.zip_code,
    ];

    classInstance.sessions?.forEach((s, index) => {
        sessionArray.push(
            <Grid
                container
                key={`session-${index + 1}`}
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item>{new Date(s.date_start).toLocaleDateString()}</Grid>
                <Grid item>
                    <div className={styleClasses.container}>
                        <FaceIcon className={styleClasses.small} />
                        <div>{`${principalTrainer?.user.full_name}`}</div>
                    </div>
                </Grid>
                <Grid item>
                    {index === 0
                        ? `${locationAddress.join(', ')}${
                              s.location_confirmed
                                  ? ' (Confirmed)'
                                  : ' (Unconfirmed)'
                          }`
                        : `${locationAddress.join(', ')}`}
                </Grid>
            </Grid>,
        );
    });

    return (
        <div {...attrs} className={styleClasses.marginVert}>
            {sessionArray}
        </div>
    );
}

ClassViewSessions.defaultProps = {
    principalTrainer: {
        user: {
            full_name: 'defaultTrainer',
        },
    },
};
