import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Divider,
    Grid,
    Radio,
} from '@material-ui/core';

import Client from '../../../client';
import MoveInvoiceDialog from '../../MoveInvoiceDialog';

import Invoice from '../../../store/entities/invoice/type';
import { actions as invoiceActions } from '../../../store/entities/invoice';
import { studentSelectors } from '../../../store/entities/student';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        bold: {
            fontWeight: 'bold',
        },
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        centerText: {
            textAlign: 'center',
        },
        small: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            verticalAlign: 'textBottom',
            marginRight: '2px',
        },
        topMargin: {
            marginTop: theme.spacing(1),
        },
        green: {
            color: green[600],
        },
        red: {
            color: red[600],
        },
    }),
);
interface CancelClassDialogProps extends React.HTMLProps<HTMLDivElement> {
    onConfirm: () => void;
    content: string;
    invoiceList: Invoice[];
    disabled: boolean;
    title?: string;
}

const CancelClassDialog: React.FunctionComponent<CancelClassDialogProps> = ({
    onConfirm,
    content,
    invoiceList,
    disabled,
    className,
    title,
}) => {
    const styleClasses = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const defaultResolutions = invoiceList.map((inv) => ({
        invoice_id: inv.id,
        resolution: 'void',
        class_id: '',
        doneMoving: false,
    }));
    const [invResolutions, setInvResolutions] =
        React.useState(defaultResolutions);
    const students = useSelector(studentSelectors.selectAll);
    const invoiceSummary: JSX.Element[] = [];

    function handleClose(): void {
        setOpen(false);
    }

    const handleRadioChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
    ) => {
        const invResolutionsOptions = [...invResolutions];
        invResolutionsOptions[index] = {
            ...invResolutionsOptions[index],
            resolution: event.target.value,
        };
        setInvResolutions(invResolutionsOptions);
    };

    function resolveInvoices() {
        // for each void invoice, add INVOICE VOIDED ON {DATE}
        const voidedInvoices = invResolutions.filter(
            ({ resolution }) => resolution === 'void',
        );
        voidedInvoices.forEach((res) => {
            const thisInvoice = invoiceList.filter(
                ({ id }) => id === res.invoice_id,
            )[0];
            const { individual, amount, number } = thisInvoice;
            const invSeats = thisInvoice.seats.map((seat) => ({
                class_id: seat.class_id,
                user_id: seat.user_id,
                states: seat.states.map((st) => st.id),
            }));
            const existingInvNotes = thisInvoice.notes || '';
            Client.put(`api/invoices/${res.invoice_id}`, {
                individual,
                amount,
                number,
                paid_amount: thisInvoice.paid_amount,
                date_issued: thisInvoice.date_issued,
                seats: invSeats,
                notes: `INVOICE VOIDED ON ${new Date().toLocaleString()} | ${existingInvNotes}`,
            }).then(async (resp) => {
                if (resp) {
                    const getInvResponse = await Client.get(
                        `api/invoices/${res.invoice_id}`,
                    );
                    const updatedInvoice = getInvResponse.data;
                    dispatch(
                        invoiceActions.invoiceUpdate({
                            id: res.invoice_id,
                            changes: {
                                notes: updatedInvoice.notes,
                            },
                        }),
                    );
                }
            });
        });
    }

    if (invoiceList.length) {
        invoiceSummary.push(
            <Grid
                container
                key="student-labels"
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Grid item className={styleClasses.bold} xs={3}>
                    Client Name
                </Grid>
                <Grid item className={styleClasses.bold} xs={2}>
                    Invoice Number
                </Grid>
                <Grid item className={styleClasses.bold} xs={1}>
                    Status
                </Grid>
                <Grid
                    item
                    className={clsx(styleClasses.bold, styleClasses.centerText)}
                    xs={1}
                >
                    Seats
                </Grid>
                <Grid item xs={3} />
            </Grid>,
        );
    }

    invoiceList.forEach((inv, index) => {
        const student = students.find(({ id }) => id === inv.seats[0]?.user_id);
        invoiceSummary.push(
            <Grid
                container
                key={`invoice-${inv.id}`}
                justify="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={3}>
                    {`${
                        inv.individual ? student?.full_name : inv.company?.name
                    }`}
                </Grid>
                <Grid item xs={2}>
                    {`${inv.number}`}
                </Grid>
                <Grid item xs={1}>
                    <div className={styleClasses.container}>
                        <MonetizationOnIcon
                            className={clsx(
                                styleClasses.small,
                                inv.amount > inv.paid_amount
                                    ? styleClasses.red
                                    : styleClasses.green,
                            )}
                        />
                        {`${inv.amount}`}
                    </div>
                </Grid>
                <Grid item className={styleClasses.centerText} xs={1}>
                    {`${inv.seats.length}`}
                </Grid>
                <Grid
                    item
                    xs={5}
                    container
                    key={`invoice-${inv.id}-resolution`}
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={1}>
                        <Radio
                            checked={
                                invResolutions[index].resolution === 'void'
                            }
                            onChange={(e) => handleRadioChange(e, index)}
                            value="void"
                            name="radio-button-void"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        Void invoice.
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={1}>
                        <Radio
                            checked={
                                invResolutions[index].resolution === 'move'
                            }
                            onChange={(e) => handleRadioChange(e, index)}
                            value="move"
                            name="radio-button-move"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        Move invoice.
                    </Grid>
                    <Grid item xs={4}>
                        <MoveInvoiceDialog
                            invoiceId={inv.id}
                            disabled={
                                invResolutions[index].resolution === 'void'
                            }
                            buttonRender={
                                <SwapVertIcon
                                    className={
                                        invResolutions[index].resolution ===
                                        'void'
                                            ? styleClasses.root
                                            : styleClasses.green
                                    }
                                />
                            }
                            onConfirm={() => {
                                const invResolutionsOptions = [
                                    ...invResolutions,
                                ];
                                invResolutionsOptions[index] = {
                                    ...invResolutionsOptions[index],
                                    doneMoving: true,
                                };
                                setInvResolutions(invResolutionsOptions);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>,
        );
        invoiceSummary.push(<Divider className={styleClasses.topMargin} />);
    });

    return (
        <>
            <Button
                variant="contained"
                className={className}
                disabled={disabled}
                startIcon={<CancelIcon />}
                onClick={() => setOpen(true)}
            >
                Cancel class
            </Button>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {content}
                    </DialogContentText>
                    {invoiceSummary}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setInvResolutions(defaultResolutions);
                            handleClose();
                        }}
                        color="default"
                        id="confirm-dialog-button-cancel"
                    >
                        Return to class
                    </Button>
                    <Button
                        onClick={() => {
                            resolveInvoices();
                            onConfirm();
                            handleClose();
                        }}
                        color="primary"
                        id="confirm-dialog-button-confirm"
                        disabled={
                            Boolean(invResolutions.length) &&
                            invResolutions
                                .map(
                                    (res) =>
                                        res.resolution === 'void' ||
                                        res.doneMoving,
                                )
                                .includes(false)
                        }
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CancelClassDialog.defaultProps = {
    title: '',
};

export default CancelClassDialog;
