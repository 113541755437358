import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import {
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    Grid,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AddAddressDialog from '../../AddAddressDialog';

import Session from '../../../store/entities/session/type';
import Location from '../../../store/entities/location/type';
import { locationSelectors } from '../../../store/entities/location';
import { stateSelectors } from '../../../store/entities/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        locationTitle: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        locationDelete: {
            'margin-right': theme.spacing(1),
        },
        hiddenButton: {
            opacity: 0,
            color: 'transparent',
        },
        newAddressButton: {
            'margin-top': theme.spacing(3),
            'margin-left': theme.spacing(3),
        },
    }),
);

export interface Props extends React.HTMLProps<HTMLDivElement> {
    sessionIndex: number;
    sessions: Session[];
    setSessions: React.Dispatch<React.SetStateAction<Session[]>>;
}

export default function FormTrainingLocation({
    sessionIndex,
    sessions,
    setSessions,
    className,
    ...attrs
}: Props) {
    const styleClasses = useStyles();
    const stateList = useSelector(stateSelectors.selectAll);
    const defaultState = {
        label: 'All States',
        id: 'All',
    };
    const locationId = sessions[sessionIndex].location_id;
    const [stateChoice, setStateChoice] = useState(defaultState);
    const dbLocations = useSelector(locationSelectors.selectAll);
    const [locationOptions, setLocationOptions] = useState(dbLocations);
    const location = useSelector((state) =>
        locationSelectors.selectById(state, locationId || 0),
    );
    const statesWithLocations = dbLocations.map((loc) => loc.state);
    const stateSelectorOptions = stateList
        .filter(
            ({ is_state, abbreviation }) =>
                is_state && statesWithLocations.includes(abbreviation),
        )
        .map(({ name, abbreviation }) => ({
            label: name,
            id: abbreviation,
        }));

    const handleChange = (event: any) => {
        const newSessions = [...sessions];
        newSessions[sessionIndex].location_confirmed = event.target.checked;
        setSessions(newSessions);
    };

    return (
        <div {...attrs} className={clsx(styleClasses.root, className)}>
            <form autoComplete="off">
                <Grid container className={styleClasses.titleContainer}>
                    <Grid item className={styleClasses.title}>
                        <Typography variant="h6" component="h2">
                            Training Location
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-between">
                    <Grid item xs={7}>
                        <Autocomplete
                            id="usaStateAutocomplete"
                            options={stateSelectorOptions}
                            getOptionLabel={(option: any) =>
                                `${option.label} (${option.id})`
                            }
                            getOptionSelected={(option: any) =>
                                option.id === location?.state
                            }
                            value={stateChoice}
                            onChange={(event: any, newValue: any) => {
                                const filteredLocations = dbLocations.filter(
                                    (loc: Location) =>
                                        newValue
                                            ? loc.state === newValue.id
                                            : true,
                                );
                                setLocationOptions(filteredLocations);
                                setStateChoice(newValue ?? defaultState);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Filter by State"
                                    margin="normal"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        sessions[sessionIndex]
                                            .location_confirmed
                                    }
                                    color="primary"
                                    onChange={handleChange}
                                    name="confirmed-location"
                                />
                            }
                            label="Confirmed Location"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Autocomplete
                            id="addressAutocomplete"
                            options={locationOptions}
                            getOptionLabel={(option: Location) => {
                                if (!option) {
                                    return 'no options available';
                                }
                                const addressParts = [
                                    option?.addressee || '',
                                    option?.address_1 || '',
                                    option?.address_2 || '',
                                    option?.city || '',
                                    option?.state || '',
                                    option?.zip_code || '',
                                ];
                                return addressParts
                                    .filter((p) => p !== '')
                                    .join(' ');
                            }}
                            getOptionSelected={(
                                option: Location,
                                value: Location,
                            ) => option.state === value.state}
                            value={location?.state ? location : null}
                            onChange={(event: any, newValue: any) => {
                                const newSessions = [...sessions];
                                newSessions[sessionIndex].location_id = newValue
                                    ? newValue.id
                                    : null;
                                setSessions(newSessions);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location search"
                                    margin="normal"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <div className={styleClasses.newAddressButton}>
                            <AddAddressDialog mode="location" />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="locationName"
                            label="Location Name"
                            value={location?.addressee || ''}
                            onChange={() => {}}
                            helperText=""
                            fullWidth
                            disabled={!location}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="locationAddress"
                            label="Street Address"
                            value={location?.address_1 || ''}
                            helperText=""
                            fullWidth
                            disabled={!location}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextField
                            id="locationCity"
                            label="City"
                            value={location?.city || ''}
                            helperText=""
                            fullWidth
                            disabled={!location}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={2} xs={4}>
                        <TextField
                            id="locationState"
                            label="State"
                            value={location?.state || ''}
                            helperText=""
                            fullWidth
                            disabled={!location}
                            margin="dense"
                        />
                    </Grid>
                    <Grid item sm={4} xs={8}>
                        <TextField
                            id="locationZipCode"
                            label="Zip Code"
                            value={location?.zip_code || ''}
                            helperText=""
                            fullWidth
                            disabled={!location}
                            margin="dense"
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
