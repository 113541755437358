import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

interface ConfirmAddressDialogProps extends React.HTMLProps<HTMLDivElement> {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onConfirm: () => void;
    verified: boolean;
    addressComponents: any;
}

const ConfirmAddressDialog: React.FunctionComponent<
    ConfirmAddressDialogProps
> = ({ open, setOpen, onConfirm, verified, addressComponents }) => {
    const title = 'Address Validation Results';
    const unverifiedNotice = `The address you've requested was not verified by Smarty.
    Please ensure you have all the correct information and try again.`;
    const verifiedNotice = `Smarty was able to verify the following address:
    ${addressComponents.address1}, ${addressComponents.address2}
    If this is the address you want to add to the database, please confirm below.
    If this does not look like the correct address, you may cancel and try again.`;
    const content = verified ? verifiedNotice : unverifiedNotice;

    function handleClose(): void {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="address-dialog-title"
            aria-describedby="address-dialog-description"
        >
            <DialogTitle id="address-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="address-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    color="default"
                    id="address-dialog-button-cancel"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onConfirm();
                        handleClose();
                    }}
                    color="primary"
                    autoFocus
                    id="address-dialog-button-confirm"
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmAddressDialog;
