import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
    DatePicker,
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Session from '../../../store/entities/session/type';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: `calc(100% - ${theme.spacing(2)} px)`,
            },
            '& .MuiPaper-root': {
                padding: theme.spacing(1),
                'margin-top': theme.spacing(1),
                'margin-bottom': theme.spacing(1),
            },
        },
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            'margin-left': theme.spacing(1),
            flexGrow: 1,
        },
        buttonGroup: {
            'margin-right': theme.spacing(1),
        },
        hiddenButton: {
            opacity: 0,
            color: 'transparent',
        },
    }),
);

interface DateSelectProps extends React.HTMLProps<HTMLDivElement> {
    title: string;
    dateValue: Date | null;
    onDateChange: (
        date: Date | null,
        value?: string | null | undefined,
    ) => void;
}

function DateSelect({ title, dateValue, onDateChange }: DateSelectProps) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
                variant="dialog"
                label={title || null}
                value={dateValue || null}
                onChange={onDateChange}
                // onError={console.log}
                format="MM/dd/yyyy"
                showTodayButton
            />
        </MuiPickersUtilsProvider>
    );
}

interface TimeSelectProps extends React.HTMLProps<HTMLDivElement> {
    title: string;
    dateValue: Date | null;
    onDateChange: (
        date: Date | null,
        value?: string | null | undefined,
    ) => void;
}

function TimeSelect({ title, dateValue, onDateChange }: TimeSelectProps) {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                variant="dialog"
                label={title || null}
                value={dateValue || null}
                onChange={onDateChange}
                // onError={console.log}
                format="hh:mm a"
            />
        </MuiPickersUtilsProvider>
    );
}

export interface Props extends React.HTMLProps<HTMLDivElement> {
    sessionIndex: number;
    sessions: Session[];
    setSessions: React.Dispatch<React.SetStateAction<Session[]>>;
}

export default function FormSessionTime({
    sessionIndex,
    sessions,
    setSessions,
    className,
    ...attrs
}: Props) {
    const styleClasses = useStyles();

    const session = sessions[sessionIndex];

    return (
        <div {...attrs} className={styleClasses.root}>
            <form className={styleClasses.root} autoComplete="off">
                <Grid container className={styleClasses.titleContainer}>
                    <Grid item className={styleClasses.title}>
                        <Typography variant="h6" component="h2">
                            Session Time
                        </Typography>
                    </Grid>
                </Grid>
                <DateSelect
                    title="Session Date"
                    dateValue={session.date_start}
                    onDateChange={(date) => {
                        if (date) {
                            const updatedSessions = [...sessions];
                            const endDate =
                                updatedSessions[sessionIndex].date_end;
                            endDate.setFullYear(date.getFullYear());
                            endDate.setMonth(date.getMonth());
                            endDate.setDate(date.getDate());
                            updatedSessions[sessionIndex] = {
                                ...updatedSessions[sessionIndex],
                                date_start: date,
                                date_end: endDate,
                            };
                            setSessions(updatedSessions);
                        }
                    }}
                />
                <div>
                    <TimeSelect
                        title="Start Time"
                        dateValue={session.date_start}
                        onDateChange={(date) => {
                            if (date) {
                                const updatedSessions = [...sessions];
                                updatedSessions[sessionIndex] = {
                                    ...updatedSessions[sessionIndex],
                                    date_start: date,
                                };
                                setSessions(updatedSessions);
                            }
                        }}
                    />
                    <TimeSelect
                        title="End Time"
                        dateValue={session.date_end}
                        onDateChange={(date) => {
                            if (date) {
                                const updatedSessions = [...sessions];
                                updatedSessions[sessionIndex] = {
                                    ...updatedSessions[sessionIndex],
                                    date_end: date,
                                };
                                setSessions(updatedSessions);
                            }
                        }}
                    />
                </div>
            </form>
        </div>
    );
}
